<template>
  <b-row>
    <b-col sm="6">
      <b-row>
        <b-col cols="12">
          <strong>{{ $t("event_liste.filter_label_suche") }}</strong>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group size="s" :label="$t('event_liste.filter_label_wettkampfname')" label-for="input-nwpName">
            <b-form-input v-model="selectedFilters.competitionName" @keyup="filterTable"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group size="s" :label="$t('event_liste.filter_label_veranstaltungsort')">
            <b-form-input v-model="selectedFilters.place" @keyup="filterTable"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <b-form-datepicker
              id="von-datepicker"
              v-model="selectedFilters.fromDate"
              :placeholder="$t('event_liste.filter_placeholder_von')"
              :locale="$i18n.locale"
              start-weekday="1"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              v-bind="helper.datePicker.labels || {}"
              @input="filterTable"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <b-form-datepicker
              id="bis-datepicker"
              v-model="selectedFilters.toDate"
              :placeholder="$t('event_liste.filter_placeholder_bis')"
              :locale="$i18n.locale"
              start-weekday="1"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              v-bind="helper.datePicker.labels || {}"
              @input="filterTable"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
    <b-col sm="6">
      <strong>{{ $t("event_liste.filter_label_filtermoeglichkeiten") }}</strong>
      <b-form-group :label="$t('event_liste.filter_label_optionale_einstellungen')">
        <b-form-select
          v-model="selectedFilters.year"
          :options="year"
          @input="filterTable"
        ></b-form-select>
      </b-form-group>
      <b-form-group>
        <b-form-select
          v-model="selectedFilters.canton"
          :options="selectFields.cantonList"
          @input="filterTable"
        ></b-form-select>
      </b-form-group>
      <b-form-group>
        <b-form-select
          v-model="selectedFilters.category"
          :options="selectFields.nwpCategory"
          @input="filterTable"
        ></b-form-select>
      </b-form-group>
    </b-col>
    <b-col class="mb-0 mb-md-3">
      <b-button v-on:click="resetFilter" size="l"
        >{{ $t("event_liste.filter_label_filter_zuruecksetzen") }}</b-button
      >
    </b-col>
    <b-col v-if="filteredEvents.length > 0" cols="12" class="d-none d-md-block">
      <b-table
        striped
        hover
        responsive
        :items="filteredEvents"
        :fields="eventFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :primary-key="filteredEvents.id"
      >
        <template #cell(id)="row">
          <b-button size="sm" :disabled="!row.item.hasResults" :to="`/${$i18n.locale}/resultate/${row.value}`">
            {{ $t("event_liste.table_button_resultate") }}
          </b-button>
        </template>
      </b-table>
    </b-col>
    <b-col
      v-if="filteredEvents.length > 0"
      cols="12"
      class="d-block d-md-none"
    >
      <b-row v-for="event in filteredEvents" :key="event.id">
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col sm="10">
          <b-row>
            <b-col cols="12">
              {{ event.nwpName }}
            </b-col>
            <b-col cols="12">
              {{ event.nwpCategory }} - {{ formatDate(event.startDate) }}
            </b-col>
            <b-col cols="12">
              {{ event.venue }}, {{ $t("event_liste.table_lable_kanton") }} {{ event.nwpCanton }}
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="2" class="text-sm-right mt-3 mt-sm-0">
          <b-button :disabled="!event.hasResults" :to="`/${$i18n.locale}/resultate/${event.id}`"
            >{{ $t("event_liste.table_button_resultate") }}</b-button
          >
        </b-col>
      </b-row>
    </b-col>
    <b-col v-if="filteredEvents.length == 0 && !displayPageLoader" cols="12">
      <b-alert show>{{ $t('event_liste.empty_table') }}</b-alert>
    </b-col>
    <b-col
      v-if="displayPageLoader"
      cols="12"
      class="d-block"
    >
        <div
          v-if="true"
          id="pageLoader"
          class="d-flex justify-content-center align-items-center py-5"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      sortBy: 'startDate',
      sortDesc: false,
      selectFields: {
        nwpType: [],
        nwpCategory: [],
        categoryDefaultElement: [{ value: 0, text: this.$t("event_liste.filter_placeholder_kategorie_auswaehlen") }],
        eventCategoryDefaultElement: [{ value: 0, text: "Eventkategorie auswählen" }],
        veranstaltungHomologation: [
          //TODO: Daten kommen von ??? (Noch in Abklärung)
          { value: "homologiert", text: "Homologiert" },
          { value: "nicht-homologiert", text: "Nicht homologiert" },
        ],
        cantonList: [],
        correspondenceLanguage: [],
        cantonDefaultElement: [{ value: 0, text: this.$t("event_liste.filter_placeholder_kanton_auswaehlen") }],
      },
      selectedFilters: {
        competitionName: "",
        place: "",
        fromDate: "",
        toDate: "",
        canton: 0,
        category: 0,
        year: 0,
      },
      eventFields: [
        {
          key: "nwpCanton",
          label: this.$t("event_liste.table_lable_kanton"),
          sortable: true,
        },
        {
          key: "startDate",
          label: this.$t("event_liste.table_lable_austragungsdatum"),
          sortable: true,
          formatter: "formatDate",
        },
        {
          key: "nwpName",
          label: this.$t("event_liste.table_lable_wettkampfname"),
          sortable: true,
        },
        {
          key: "venue",
          label: this.$t("event_liste.table_lable_veranstaltungsort"),
          sortable: true,
        },

        {
          key: "nwpCategory",
          label: this.$t("event_liste.table_lable_wettkampfkategorie"),
          sortable: true,
        },
        {
          key: "id",
          label: "",
          sortable: false,
        },
      ],
      helper: {
        // Helpers sind variablen, die für das formular verwendet werden, aber nicht an die api geschickt werden sollen
        datePicker: {
          labels: {
            labelPrevDecade: this.$t("bootstrap.datePicker.labelPrevDecade"),
            labelPrevYear: this.$t("bootstrap.datePicker.labelPrevYear"),
            labelPrevMonth: this.$t("bootstrap.datePicker.labelPrevMonth"),
            labelCurrentMonth: this.$t(
              "bootstrap.datePicker.labelCurrentMonth"
            ),
            labelNextMonth: this.$t("bootstrap.datePicker.labelNextMonth"),
            labelNextYear: this.$t("bootstrap.datePicker.labelNextYear"),
            labelNextDecade: this.$t("bootstrap.datePicker.labelNextDecade"),
            labelToday: this.$t("bootstrap.datePicker.labelToday"),
            labelSelected: this.$t("bootstrap.datePicker.labelSelected"),
            labelNoDateSelected: this.$t(
              "bootstrap.datePicker.labelNoDateSelected"
            ),
            labelCalendar: this.$t("bootstrap.datePicker.labelCalendar"),
            labelNav: this.$t("bootstrap.datePicker.labelNav"),
            labelHelp: this.$t("bootstrap.datePicker.labelHelp"),
          },
        },
      },
      events: [],
      filteredEvents: [],
      selctedYear: 0,
      year: [],
      datumVon: null,
      datumBis: null,
      displayPageLoader: false,
    };
  },
  created() {
    this.getResultatListe();
    this.getCantonsList();
    this.getNWPCategory();
  },
  methods: {
    mapEvents(data) {
      var tmp = [];
      data.forEach((event) => {
        let tmpEvent = event;

        tmpEvent.nwpType = this.$t(event.nwpType);
        tmpEvent.nwpCanton = this.$t(event.nwpCanton);
        tmpEvent.nwpCategoryKey = event.nwpCategory;
        tmpEvent.nwpCategory = this.$t(event.nwpCategory);
        tmpEvent.contactCanton = this.$t(event.contactCanton);
        tmpEvent.correspondenceLanguage = this.$t(event.correspondenceLanguage);

        tmp.push(tmpEvent);
      });
      return tmp;
    },
    getResultatListe() {
      this.displayPageLoader = true;

      this.$store
        .dispatch("wettkampf/getResultateListe")
        .then((resp) => {
          var events = resp.data;
          // Filtere alle nicht Visana Sprint Events aus der Liste
          events = events.filter(event => event.nwpCategory.includes('Lov.Sat.NWPCategory.MilleGruyere'));
          this.events = this.mapEvents(events);
          // Sortiere Events nach Startdatum
          this.events.sort((a, b) => {
            var dateA = new Date(a.startDate);
            var dateB = new Date(b.startDate);
            return (dateA < dateB) ? 1 : ((dateB < dateA) ? -1 : 0)
          });
          this.filteredEvents = this.events;
          this.setFilterableYears();
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.displayPageLoader = false;
        });
    },
    mapLov(arr) {
      return arr.map((obj) => {
        var rObj = { value: "", text: "" };
        rObj.value = obj.lic;
        rObj.text = this.$t(obj.lic);
        return rObj;
      });
    },
    filterLovList(data, filter = "") {
      var returnData = [];
      data.forEach((obj) => {
        var objArr = obj.lic.split(".");
        if (objArr[objArr.length - 1].startsWith(filter)) {
          returnData.push(obj);
        }
      });
      return returnData;
    },
    getCantonsList() {
      this.$store
        .dispatch("lov/getCantonList")
        .then((resp) => {
          this.selectFields.cantonList = [].concat(
            this.selectFields.cantonDefaultElement,
            this.mapLov(resp.data)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNWPCategory() {
      this.$store
        .dispatch("lov/getNWPCategory")
        .then((resp) => {
          this.selectFields.nwpCategory = [].concat(
            this.selectFields.categoryDefaultElement,
            this.mapLov(this.filterLovList(resp.data, "MilleGruyere"))
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setFilterableYears() {
      // Hohle alle Jahre in denen Events geplant sind
      var filterObjects = this.events.map((event) => {
        let date = new Date(event.startDate);
        return date.getFullYear();
      });

      // Filtere alle Duplikate heraus
      filterObjects = [...new Set(filterObjects)];

      // Sortiere nach Jahr
      filterObjects.sort((a, b) => {
        return b - a;
      });

      filterObjects = [].concat(this.$t("event_liste.filter_placeholder_alle_jahre"), filterObjects);

      filterObjects = filterObjects.map((item, i) => {
        return { value: i, text: item};
      });

      this.year = filterObjects;
      this.selectedFilters.year = this.year.length >= 2 ? 1 : 0;

      this.filterTable();
    },
    formatDate(dateStr) {
      var date = new Date(dateStr);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return date.toLocaleDateString(this.$i18n.locale, options);
    },
    filterTable() {
      //reset Filter at the begining
      this.filteredEvents = this.events;

      //TODO: das muss unbedingt optimiert werden
      this.getDate();

      if (this.selectedFilters.place != "") {
        this.filteredEvents = this.filteredEvents.filter(
          item =>
            item.venue
              .toLowerCase()
              .indexOf(this.selectedFilters.place.toLowerCase()) > -1
        );
      }
      if (this.selectedFilters.competitionName != "") {
        this.filteredEvents = this.filteredEvents.filter(
          (item) =>
            item.nwpName
              .toLowerCase()
              .indexOf(this.selectedFilters.competitionName.toLowerCase()) > -1
        );
      }

      if (this.selectedFilters.category != 0) {
        var categoryText;
        this.selectFields.nwpCategory.forEach((element) => {
          if (element.value == this.selectedFilters.category) {
            categoryText = element.text;
          }
        });

        if (categoryText != "") {
          this.filteredEvents = this.filteredEvents.filter(
            (item) => item.nwpCategory.indexOf(categoryText) > -1
          );
        }
      }

      if (this.selectedFilters.canton != 0) {
        var cantonText;
        this.selectFields.cantonList.forEach((element) => {
          if (element.value == this.selectedFilters.canton) {
            cantonText = element.text;
          }
        });

        if (cantonText != "") {
          this.filteredEvents = this.filteredEvents.filter(
            (item) => item.nwpCanton.indexOf(cantonText) > -1
          );
        }
      }
    },

    resetFilter() {
      this.selectedFilters.competitionName = "";
      this.selectedFilters.place = "";
      this.selectedFilters.fromDate = "";
      this.selectedFilters.toDate = "";
      this.selectedFilters.canton = 0;
      this.selectedFilters.category = 0;
      this.selectedFilters.year = 0;

      this.filteredEvents = this.events;
    },

    getDate() {
      var vonDatum = "";
      var bisDatum = "";
      //der Datumfilter hat priorität gegebenüber dem Jahrfilter
      if (
        this.selectedFilters.fromDate != "" ||
        this.selectedFilters.toDate != ""
      ) {
        if (this.selectedFilters.fromDate != "") {
          vonDatum = new Date(this.selectedFilters.fromDate);
        }
        if (this.selectedFilters.toDate != "") {
          bisDatum = new Date(this.selectedFilters.toDate);
        }

        if (vonDatum == "") {
          vonDatum = new Date(2000, 1, 1);
        }

        if (bisDatum == "") {
          bisDatum = new Date(2030, 12, 31);
        }
      } else {
        if (this.selectedFilters.year != "") {
          vonDatum = new Date(this.year[this.selectedFilters.year].text, 1, 1);
          bisDatum = new Date(
            this.year[this.selectedFilters.year].text,
            12,
            31
          );
        }
      }
      if(vonDatum!="")
      {
        this.filterTableByDate(vonDatum, bisDatum);
      }
    },

    filterTableByDate(vonDatum, bisDatum) {
      this.filteredEvents = this.filteredEvents.filter((element) => {
        var startDate = new Date(element.startDate);
        return (startDate >= vonDatum) & (startDate <= bisDatum);
      });
    },
  },
};
</script>